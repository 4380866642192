// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-1-js": () => import("./../src/pages/1.js" /* webpackChunkName: "component---src-pages-1-js" */),
  "component---src-pages-2-js": () => import("./../src/pages/2.js" /* webpackChunkName: "component---src-pages-2-js" */),
  "component---src-pages-3-js": () => import("./../src/pages/3.js" /* webpackChunkName: "component---src-pages-3-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

